(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/detail-view-route-play-handler/assets/javascripts/header-bet-buttons.js') >= 0) return;  svs.modules.push('/components/tipsen/detail-view-route-play-handler/assets/javascripts/header-bet-buttons.js');
"use strict";

const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const {
  CouponBetButtons
} = svs.components.tipsen.couponBetButtons;
const {
  useCurrentDrawEvent
} = svs.components.tipsen.drawProvider.hooks;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useSelector
} = ReactRedux;
const {
  selectIsReduceSystemChosen
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  getOutcomeLabelsForEventType
} = svs.components.sport.tipsenShared;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const HeaderBetButtons = () => {
  const couponId = useCouponId();
  const event = useCurrentDrawEvent();
  const draw = useCurrentDraw();
  const eventTypeId = event === null || event === void 0 ? void 0 : event.eventTypeId;
  const isApprovedEventType = eventTypeId === EventTypeId.EVENT_1X2 || eventTypeId === EventTypeId.HEAD_TO_HEAD || eventTypeId === EventTypeId.GOAL_COUNT;
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const isReduceSystem = useSelector(state => selectIsReduceSystemChosen(state, couponId));
  if (isReduceSystem || !isApprovedEventType || !couponId) {
    return null;
  }
  const outcomeLabels = getOutcomeLabelsForEventType(eventTypeId, draw === null || draw === void 0 ? void 0 : draw.sportType);
  const outcomes = outcomeLabels.map(el => el.text);
  return React.createElement(CouponBetButtons, {
    board: 0,
    couponId: couponId,
    event: event.eventNumber - 1,
    hasDisabledBets: !isDrawPlayable,
    outcomeLabels: outcomeLabels,
    outcomes: outcomes
  });
};
setGlobal('svs.components.tipsen.detailViewRoutePlayHandler.HeaderBetButtons', HeaderBetButtons);

 })(window);